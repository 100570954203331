<template>
    <div class="main">
        <div class="banner">
            <div class="banner-overlay"></div>
             <h1>REFERENCES &<br />CAS D'USAGE</h1>
        </div>
        <section class="title-section">
            <div class="row">
                <h2 class="gradiant-text">Quelques exemples de salons et congrès virtuels réalisés via notre solution "My-Virtual-Convention"</h2>
            </div>
        </section>
        <section class="customers">
            <div class="sep sep-top"></div>  
            <div class="references-container">
                    <div class="customers-item" v-for="(item, idx) in refs" :key="idx">
                        <img :src="item.logo" :alt="item.alt" />
                        <div class="customers-title">
                            <h3 class="gradiant-text" v-html="item.name"></h3>
                            <h3 class="gradiant-text" v-html="item.title"></h3>
                            <h4 class="gradiant-text" v-html="item.date"></h4>
                        </div>
                        <div class="customers-obj">
                            <p><strong>Objectifs :</strong></p>
                            <p v-for="(text, idz) in item.purpose" :key="idz" v-html="text"></p>
                        </div>
                        <div class="customers-video" v-if="item.video">
                            <vueVimeoPlayer 
                                ref="player"
                                :video-url="item.video"    
                                player-height="180"
                                player-width="320"                   
                            >
                            </vueVimeoPlayer>
                        </div>
                    </div>
            </div>
        </section>
    </div>
</template>


<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
    name : 'References',
    components : {
        vueVimeoPlayer
    },
    data() {
        return {
            refs : [
                {
                    name : 'E-COSMET\'AGORA 2021',
                    title : 'OODT & SOCIETE FRANCAISE DE COSMETOLOGIE (SFC)',
                    date : 'Du 6 au 9 Avril 2021',
                    logo : require('@/assets/logo-ecosmetagora.png'),
                    alt : 'logo E-COSMET\'AGORA',
                    purpose : [
                        '• Digitaliser l’événement du Cosmet’Agora qui a lieu tous les ans en présentiel habituellement',
                        '• Regrouper pendant 4 jours tous les acteurs de la Cosmétique pour leur permettre de présenter leurs innovations et organiser des RDVs',
                        '• Organiser en ligne le « Prix de la Formulation 2021 »'
                    ],
                    video : 'https://vimeo.com/538267632'
                },
                {
                    name : 'VI(H)E QUOTIDIENNE',
                    title : 'LA FONDERIE & DIFFERENTES ASSOCIATIONS PARTENAIRES',
                    date : 'Le 23 Mars 2021',
                    logo : require('@/assets/logo-gilead.png'),
                    alt : 'logo GILEAD',
                    purpose : [
                        '• Organiser une journée d’information sur le thème du VIH',
                        '• Fédérer et mettre en avant des associations partenaires autour de l\'événement',
                        '• Offrir aux participants anonymes une grande richesse d’informations sur le VIH'
                    ],
                    video : 'https://vimeo.com/538267678'
                },
                {
                    name : 'JOURNEES PORTES OUVERTES (JPO) DE L’ECOLE AUTOGRAF',
                    title : 'AUTOGRAF',
                    date : 'Les 19 & 20 Mars 2021',
                    logo : require('@/assets/logo-autograf.png'),
                    alt :'logo AUTOGRAF',
                    purpose : [
                        '• Promouvoir sur 2 journées toutes les formations de l’école',
                        '• Offrir la possibilité de tchatter en ligne avec tous les intervenants et les professeurs',
                        '• Découvrir les travaux des étudiants des différentes formations'
                    ],
                    video : 'https://vimeo.com/538267706'
                },
                {
                    name : 'MATINÉE CYBERSÉCURITÉ - Comment déjouer les cyberattaques ?',
                    title : 'CELAPROD & INSIGHT',
                    date : 'Le 17 Novembre 2020 matin',
                    logo : require('@/assets/logo-insight.png'),
                    alt :'logo INSIGHT',
                    purpose : [
                        '• Accompagner un Webinar autour du thème de la Cybersécurité',
                        '• Fédérer et promouvoir des partenaires autour de l’événement',
                        '• Offrir aux participants l\'accès à une expérience immersive et informative originale'
                    ],
                    video : null
                },
                {
                    name : 'CARTILAGE REPAIR 2020 VIRTUAL CONVENTION',
                    title : 'ICRS en partanariat avec OTW Virtual Conventions',
                    date : '4 sessions sur 4 semaines en Octobre 2020',
                    logo : require('@/assets/logo-icrs.png'),
                    alt :'logo ICRS',
                    purpose : [
                        '• Maintenir les conférences scientifiques dans le contexte de la COVID-19',
                        '• Faciliter la génération de leads pour les exposants',
                        '• Permettre aux visiteurs l\'accès aux conférences en Live puis en Replay'
                    ],
                    video : null
                },
                {
                    name : 'MASTERING THE DIRECT ANTERIOR APPROACH',
                    title : 'ICJR en partanariat avec OTW Virtual Conventions',
                    date : '4 sessions sur 4 semaines en Octobre 2020',
                    logo : require('@/assets/logo-icjr.png'),
                    alt :'logo ICJR',
                    purpose : [
                        '• Maintenir les conférences scientifiques dans le contexte de la COVID-19',
                        '• Faciliter la génération de leads pour les exposants',
                        '• Faciliter l’accessibilité au contenu pour les visiteurs'
                    ],
                    video : null
                },
                {
                    name : 'ICJR INSIGHTS: ADVANCES IN HIP AND KNEE ARTHROPLASTY',
                    title : 'ICJR en partanariat avec OTW Virtual Conventions',
                    date : '4 sessions sur 4 semaines en Septembre 2020',
                    logo : require('@/assets/logo-icjr.png'),
                    alt :'logo ICJR',
                    purpose : [
                        '• Maintenir les conférences scientifiques dans le contexte de la COVID-19',
                        '• Faciliter la génération de leads pour les exposants',
                        '• Faciliter l’accessibilité au contenu pour les visiteurs'
                    ],
                    video : null
                },
                {
                    name : '2020 Castellvi Spine Symposium',
                    title : 'FORE en partanariat avec OTW Virtual Conventions',
                    date : '4 sessions sur 4 semaines en Septembre 2020',
                    logo : require('@/assets/logo-castellvi.png'),
                    alt :'logo CASTELLVI',
                    purpose : [
                        '• Maintenir les conférences scientifiques dans le contexte de la COVID-19',
                        '• Faciliter la génération de leads pour les exposants',
                        '• Permettre aux visiteurs l\'accès aux conférences en Live puis en Replay'
                    ],
                    video : null
                },
            ]
        }
    }
}
</script>

<style scoped lang="scss">
    .banner {
        background-image : url('./../assets/bg-ref.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .title-section .row {
        width: $boxWidth;
        max-width: 96%;  
        justify-content: center; 
        margin: 40px auto;
    }

    .title-section .row h2 {
        max-width: 80%;
        text-transform: uppercase;
    }

    .customers {
        background: $backgroundGray;
        padding:100px 0;
        display: flex;
        justify-content: center;
        position: relative;
        align-items:center;
        flex-direction: column;
    }


    .references-container {            
        width: $boxWidth;
        max-width: 96%;  
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .customers-video {
        width:100%;
        max-width: 250px;
        height:200px;
        margin-top:20px;
        display: flex;
        justify-content: center;
    }


    .sep-top{
        background-image: url('./../assets/border-top-left-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .customers-item {
        background:#fff;
        width: calc(50% - 120px);
        margin: 20px auto;
        border-radius: 10px;
        padding:30px 40px;
        display: flex;
        flex-direction: column;
        align-items:center;
        min-height:410px;

    }

    .customers-item img {
        height:100px;
        min-height:100px;
        width: auto;
    }

    .customers-title {
        margin: 20px auto;
    }

    .customers-title h3 {
        text-align: center;
        font-weight: 700;
        margin-top:0;
        margin-bottom: 10px;
    }

    .customers-title h4 {
        text-align: center;
        font-weight: 400;
        margin:0;
    }

    @media screen and (max-width: 1400px) {


        .customers-item {
            height:auto;
            width: 100%;
            max-width: 600px;
            min-height:auto;
        }
    }
</style>